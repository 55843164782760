<template>
    <div id="contentcontainter" class="standard-mode">
        <v-dialog id="endoftest-dialog" v-model="dialog" max-width="800px" persistent>
            <div class="maincard">
                <div class="vh">
                    <div>
                        <div class="wrap">
                            <img src="../assets/icons/__rocket02_allwhite.svg" width="200px" class="illustration" alt="" />
                            <h1 class="mt-5">Your free trial has expired!</h1>

                            <h2>
                                <p>Upgrade now to unlock all features.<br /></p>
                            </h2>
                            <v-btn @click="$router.push('/pricing')" class="mb-10" dark>
                                Upgrade now
                                <v-icon small right dark class="ml-1">mdi-rocket-launch-outline</v-icon>
                            </v-btn>
                            <!-- <checkout v-if="true" :darkbtn="true" class="mb-10"></checkout> -->
                            <!-- <div class="msgbox mt-10">
                            <span class="text">Thank you for testing Quaro. We would appreciate your feedback!<b> contact@quaro.io</b></span>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "EndOfTest",
        components: {
            Checkout: () => import("../components/checkout/Checkout")
        },
        data() {
            return { dialog: true }
        },
        computed: {},
        methods: {},
        mounted() {}
    }
</script>

<style lang="scss" scoped>
    .v-dialog {
        border-radius: 50px !important;
    }
    .maincard {
        text-align: center;
        margin: 0px;
        padding: 0px;
        height: 100%;
        color: #fff;
        background: linear-gradient(-45deg, #009f89, #1cedb2);
        background-size: 100% 100%;
        -webkit-animation: Gradient 15s ease infinite;
        -moz-animation: Gradient 15s ease infinite;
        animation: Gradient 15s ease infinite;
    }
    .vh {
        height: 100%;
        align-items: center;
        display: flex;
    }
    .vh > div {
        width: 100%;
        text-align: center;
        vertical-align: middle;
    }
    img {
        max-width: 100%;
    }
    .wrap {
        margin-top: 50px;
        text-align: center;
    }
    .wrap h1 {
        font-size: 30px;
        font-weight: 700;
    }
    .wrap h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.6;
    }
    .msgbox {
        display: flex;
        margin-bottom: 50px;
        margin-top: 50px;
        .text {
            border: 1px dashed rgb(253, 253, 253);
            border-radius: 5px;
            padding: 2px 7px 2px 7px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @-webkit-keyframes Gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-moz-keyframes Gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes Gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
</style>
