import { render, staticRenderFns } from "./EndOfTest.vue?vue&type=template&id=22788830&scoped=true&"
import script from "./EndOfTest.vue?vue&type=script&lang=js&"
export * from "./EndOfTest.vue?vue&type=script&lang=js&"
import style0 from "./EndOfTest.vue?vue&type=style&index=0&id=22788830&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22788830",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDialog,VIcon})
